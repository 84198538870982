import React from "react";

import {CreateStaffUserDto} from "@reside/reside-api-app";
import {FormCard, TagsField} from "@reside/ui";
import {useFormikContext} from "formik";

import {useFacilitiesQuery} from "../hooks/useFacilitiesQuery";
import {BackdropSpinner} from "../../../atoms/spinner";

export const FacilitiesTags = (): JSX.Element => {
  const {
    values: {organizationId},
  } = useFormikContext<CreateStaffUserDto>();

  const {data: facilities = [], isFetching} = useFacilitiesQuery({
    organizationId,
  });

  const facilityOptions = facilities.map(facility => ({
    value: facility.id,
    label: facility.name,
  }));

  return (
    <FormCard title="Facilities">
      <BackdropSpinner active={isFetching}>
        <TagsField
          name="facilities"
          options={facilityOptions}
          placeholder="Start typing facility name"
          label="Select Facility"
          testId="facilitySelect"
        />{" "}
      </BackdropSpinner>
    </FormCard>
  );
};
