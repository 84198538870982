var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import { styled } from "../theme";
export var DropdownMenuItemBase = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 15px;\n  color: ", ";\n  font-size: 15px;\n  font-weight: ", ";\n  position: relative;\n  cursor: ", ";\n  ", "\n\n  ", "\n"], ["\n  padding: 15px;\n  color: ", ";\n  font-size: 15px;\n  font-weight: ", ";\n  position: relative;\n  cursor: ", ";\n  ", "\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
}, function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.medium;
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "not-allowed" : "pointer");
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled
        ? "opacity: 0.5;"
        : "\n    &:hover {\n      background-color: ".concat(theme.color.lightBlue10, ";\n    }\n    ");
}, function (_a) {
    var active = _a.active, theme = _a.theme;
    return active && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), theme.color.lightBlue10);
});
var templateObject_1, templateObject_2;
