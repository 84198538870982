import React from "react";
import {css} from "@emotion/css";
import {OrganizationDto} from "@reside/reside-api-app";
import {FormCard, SelectField} from "@reside/ui";

export const OrganizationSelect = ({
  organizations,
}: {
  organizations: readonly OrganizationDto[];
}): JSX.Element => (
  <FormCard title="Organization">
    <SelectField<OrganizationDto>
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      label="Select Organization"
      name="organizationId"
      options={organizations}
      className={css(`
& > select {
padding-left: 0px;
}
`)}
    />
  </FormCard>
);
