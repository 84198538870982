import {styled} from "@reside/ui";

export const ResideAdminTabsSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.color.gray10};
  position: relative;
  margin: 20px 0;

  &::before {
    content: "Reside Admin";
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-20%, -50%);
    background-color: ${({theme}) => theme.color.white};
    padding: 0 10px;
    color: ${({theme}) => theme.color.primary};
  }
`;
