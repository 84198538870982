import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {History} from "history";

import {UserService} from "../../../services/UserService";
import {buildResponseErrorMessage} from "../../../services/api/errorHelpers";

export const useCreateUser = (history: History) =>
  useMutation(UserService.create, {
    onSuccess: () => {
      Alert.success("User was successfully created.");
      history.push("/admin/users");
    },
    onError: async (resp: any) => {
      Alert.error(
        await buildResponseErrorMessage(
          resp,
          "An error occurs when creating user.",
        ),
      );
    },
  });
