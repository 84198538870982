import {ApiErrorResponse, CANCEL_ERROR} from "apisauce";
import {RawAxiosResponseHeaders} from "axios";

export const requestCancelled = <E = any>(error: ApiErrorResponse<E>) =>
  error.problem === CANCEL_ERROR;

export const getRefreshedToken = (headers: RawAxiosResponseHeaders) => {
  const token = headers?.["x-refreshed-jwt"];

  return typeof token === "string" ? token.split(" ")[1] : null;
};
