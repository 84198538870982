import {ChangeEvent, useState} from "react";
import {SelectField, FlexRow} from "@reside/ui";
import {Formik} from "formik";
import {useTemplateNamesQuery} from "../../services/hooks/useTemplateNamesQuery";
import {Spinner} from "../../atoms/spinner";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {ButtonLink} from "../../atoms/button-link";
import {Space} from "../../atoms/space";
import {useRouteMatch} from "react-router";
import {useEventTriggersQuery} from "../../services/hooks/useEventTriggersQuery";
import {Column, ListTable} from "../../atoms/list-table";
import {EventTrigger} from "../../services/HubAndSpokeService";
import {ButtonsWrapper, PageWrapper} from "./styles";

type Row = {
  rowData: EventTrigger;
};

export const EventTriggers = () => {
  const match = useRouteMatch();
  const [templateName, setTemplateName] = useState<string>();

  const {isLoading: isLoadingTemplateNames, data: templateNames = []} =
    useTemplateNamesQuery();
  const {isLoading: isLoadingEventTriggers, data: eventTriggers = []} =
    useEventTriggersQuery({templateName});

  return (
    <PageWrapper>
      <FlexRow>
        <AdminPageTitle>Event Triggers</AdminPageTitle>
        {isLoadingTemplateNames ? (
          <Spinner />
        ) : (
          <Space>
            <Formik initialValues={{}} onSubmit={() => undefined}>
              <SelectField
                label="Template"
                name="template-name"
                options={templateNames.map(template => ({
                  label: template,
                  value: template,
                }))}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  const newTemplateName = event.target.value;
                  setTemplateName(newTemplateName);
                }}
              />
            </Formik>
            <ButtonLink to={`${match.path}/create`}>
              Create new Event Trigger
            </ButtonLink>
          </Space>
        )}
      </FlexRow>
      <FlexRow>
        {isLoadingEventTriggers ? (
          <Spinner />
        ) : (
          <ListTable
            key="event-triggers"
            data={
              Array.isArray(eventTriggers) ? eventTriggers : [eventTriggers]
            }
          >
            <Column
              cellRenderer={(row: Row) => (
                <p>{row.rowData.template_field.template_name}</p>
              )}
              label="Template Name"
              sortKey="template_field.template_name"
              width={200}
            />
            <Column
              cellRenderer={(row: Row) => (
                <p>{row.rowData.template_field.friendly_name}</p>
              )}
              label="Template Field Friendly Name"
              sortKey="template_field.friendly_name"
              width={200}
            />
            <Column
              cellRenderer={(row: Row) => (
                <p>
                  {row.rowData.facilities
                    .map(facility => facility.name)
                    .join(", ")}
                </p>
              )}
              label="Facilities"
              width={200}
            />
            <Column
              cellRenderer={(row: Row) => <p>{row.rowData.sftp_config.name}</p>}
              label="SFTP Config Name"
              width={200}
            />
            <Column
              cellRenderer={(row: Row) => (
                <p>{row.rowData.sftp_config.hostname}</p>
              )}
              label="SFTP Config Hostname/IP Address"
              width={200}
            />
            <Column
              cellRenderer={(row: Row) => <p>{row.rowData.document}</p>}
              label="Document"
              sortKey="document"
              width={200}
            />
            <Column
              cellRenderer={(row: Row) => (
                <p>{row.rowData.upload_on.map(item => item.replace(/_/g, ' ')).join(', ')}</p>
              )}
              label="Upload On"
              sortKey="upload_on"
              width={200}
            />
            <Column
              cellRenderer={({rowData: {id}}: Row) => (
                <ButtonsWrapper>
                  <ButtonLink to={`${match.path}/${id}`}>View</ButtonLink>
                  <ButtonLink to={`${match.path}/edit/${id}`} color="primary">
                    Edit
                  </ButtonLink>
                </ButtonsWrapper>
              )}
            />
          </ListTable>
        )}
      </FlexRow>
    </PageWrapper>
  );
};
