import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {CreateStaffUserDto} from "@reside/reside-api-app";

import {DocumentTitle} from "../../atoms/document-title";
import {AdminLayout} from "../../atoms/admin-layout";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {UserFormHeader} from "./atoms/UserFormHeader";
import {UserFormik} from "./atoms/UserFormik";
import {useCreateUser} from "./hooks/useCreateUser";

export const PageAdminUserCreate = ({history}: RouteComponentProps) => {
  const {mutate: createUser, isLoading: isCreatingUser} =
    useCreateUser(history);

  return (
    <>
      <DocumentTitle title="Reside - Admin - New User" />
      <AdminLayout>
        <UserFormik
          initialValues={{
            phone: "",
            roleId: "",
            firstName: "",
            lastName: "",
            email: "",
            organizationId: "",
            facilities: [],
            type: CreateStaffUserDto.TypeEnum.CLERK,
          }}
          onSubmit={createUser}
          userFormHeader={
            <UserFormHeader
              isSubmitting={isCreatingUser}
              title={<AdminPageTitle>New User</AdminPageTitle>}
            />
          }
        />
      </AdminLayout>
    </>
  );
};
