import {getResidentTestId} from "../../helper";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";
import {StyledDropdownMenuItem} from "./styles";

export const ViewAdmissionDropdownItem = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => (
  <StyledDropdownMenuItem
    asLink
    to={`/admin/admissions/${admission.id}`}
    target="_blank"
    testId={getResidentTestId(admission, "view")}
  >
    View Admission
  </StyledDropdownMenuItem>
);
