import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {validator} from "@reside/forms";
import {CreateStaffUserDto} from "@reside/reside-api-app";
import {ThinScrollbar} from "@reside/ui";

import {useOrganizationsQuery} from "../hooks/useOrganizationsQuery";
import {BackdropSpinner} from "../../../atoms/spinner";
import {normalizeValues, getValidationRules} from "./helpers";
import {SaveBeforeLeavePrompt} from "../../../atoms/save-before-leave-prompt/SaveBeforeLeavePrompt";
import {UserFormBody} from "./UserFormBody";

type Props = Readonly<{
  initialValues: CreateStaffUserDto;
  onSubmit: (values: CreateStaffUserDto) => void;
  userFormHeader: React.ReactNode;
  deleteUserButton?: React.ReactNode;
  resetPasswordButton?: React.ReactNode;
  resendActivationEmailButton?: React.ReactNode;
}>;

export const UserFormik: FunctionComponent<Props> = ({
  initialValues,
  onSubmit,
  userFormHeader,
  deleteUserButton = null,
  resetPasswordButton = null,
  resendActivationEmailButton = null,
}) => {
  const {data: organizations = [], isFetching: isFetchingOrganizations} =
    useOrganizationsQuery();

  const validate = (values: CreateStaffUserDto) =>
    validator.validateAll(values, getValidationRules(values, organizations));

  return (
    <Formik<CreateStaffUserDto>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(normalizeValues(values))}
      validate={validate}
    >
      {() => (
        <BackdropSpinner active={isFetchingOrganizations}>
          <SaveBeforeLeavePrompt />
          {userFormHeader}
          <ThinScrollbar>
            <UserFormBody
              deleteUserButton={deleteUserButton}
              resetPasswordButton={resetPasswordButton}
              resendActivationEmailButton={resendActivationEmailButton}
              organizations={organizations}
            />
          </ThinScrollbar>
        </BackdropSpinner>
      )}
    </Formik>
  );
};
