import {CreateStaffUserDto, UserDto} from "@reside/reside-api-app";

export const getInitialValues = ({
  firstName,
  lastName,
  phone,
  email,
  role,
  type,
  organization,
  facilities = [],
}: UserDto): CreateStaffUserDto => ({
  firstName,
  lastName,
  phone,
  email,
  roleId: role.id,
  type,
  organizationId: organization?.id ?? "",
  facilities: facilities.map(({id}) => id),
});
