import React from "react";
import {BlockComponentContextProvider, FullScreenModal} from "@reside/ui";

import {AdmissionStatus} from "../../../services/AdmissionsService";
import {
  CompletionCheck,
  Draft,
  DraftActions,
  UIActions,
  UIState,
} from "../model";
import {SlideShow} from "../slideshow";
import {HelpControlModal, HelpControlReminder} from "../help-control";
import {PauseOverlayModal} from "../pause-overlay-modal";
import {SignAdmissionOverlay} from "../sign-admission";
import {AdmissionContextProvider} from "../AdmissionContext";

import {ConnectedTableOfContentsProvider} from "../ToCContext";
import {admissionBlockComponents} from "../AdmissionBlockComponentsContextProvider";

type Props = Readonly<{
  draft: Draft;
  draftActions: DraftActions;
  uiActions: UIActions;
  uiState: UIState;
  completionChecklist: CompletionCheck[];
  logoutSession: () => void;
  loadingPatchAdmission: boolean;
  hasClearHiddenFieldsFlag: boolean;
}>;

export const AdmissionExplorer = ({
  draft,
  draftActions,
  uiState,
  uiActions,
  completionChecklist,
  logoutSession,
  loadingPatchAdmission,
  hasClearHiddenFieldsFlag,
}: Props) => (
  <AdmissionContextProvider
    {...{
      draft,
      draftActions,
      uiState,
      uiActions,
      completionChecklist,
      logoutSession,
      loadingPatchAdmission,
      hasClearHiddenFieldsFlag,
    }}
  >
    <ConnectedTableOfContentsProvider admissionId={draft.instance.id}>
      <BlockComponentContextProvider value={admissionBlockComponents}>
        <SlideShow />
        <PauseOverlayModal
          isOpen={uiState.paused}
          onLogout={logoutSession}
          onClose={uiActions.resume}
          textSize={uiState.textSize}
        />

        <FullScreenModal
          noCloseButton
          isOpen={uiState.signModalIsOpen}
          onRequestClose={uiActions.closeSignModal}
        >
          {() => (
            <SignAdmissionOverlay
              onRequestClose={uiActions.closeSignModal}
              {...{draft, draftActions, uiState, uiActions, logoutSession}}
            />
          )}
        </FullScreenModal>
        <HelpControlModal />
        <HelpControlReminder
          disabled={draft.instance.status === AdmissionStatus.SIGNED}
        />
      </BlockComponentContextProvider>
    </ConnectedTableOfContentsProvider>
  </AdmissionContextProvider>
);
