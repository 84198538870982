import React from "react";

import {
  FormGrid,
  FormSlide,
  FormCard,
  SelectField,
  TextField,
} from "@reside/ui";

import {userTypes, visibleRolesFor} from "../../../utils/constants";
import {readableRole} from "../../../atoms/list-table/helpers";
import {OrganizationSelect} from "./OrganizationSelect";
import {FacilitiesTags} from "./FacilitiesTags";
import {useFormikContext} from "formik";
import {
  CreateStaffUserDto,
  OrganizationDto,
  UserDto,
} from "@reside/reside-api-app";
import {connect} from "react-redux";
import {AppState, select} from "../../../store";
import {StyledScrollableErrorArea} from "./styles";

type Props = Readonly<{
  user: UserDto;
  organizations: readonly OrganizationDto[];
  deleteUserButton?: React.ReactNode;
  resetPasswordButton?: React.ReactNode;
  resendActivationEmailButton?: React.ReactNode;
}>;

export const UserFormBodyRenderer = ({
  user,
  organizations,
  deleteUserButton = null,
  resetPasswordButton = null,
  resendActivationEmailButton = null,
}: Props): JSX.Element => {
  const {values} = useFormikContext<CreateStaffUserDto>();

  const roles = visibleRolesFor(user.role.name);

  return (
    <StyledScrollableErrorArea>
      <FormGrid>
        <FormSlide>
          <FormCard title="User Details">
            <TextField label={"First Name"} name="firstName" />
            <TextField label={"Last Name"} name="lastName" />
            <SelectField
              label={"Role"}
              name="roleId"
              options={roles}
              getOptionLabel={role => readableRole(role)}
              getOptionValue={role => userTypes[role]}
            />
            <TextField label={"Email Address"} name="email" type="email" />
            <TextField label={"Phone"} name="phone" format="us-phone" />
            {resetPasswordButton}
            {resendActivationEmailButton}
          </FormCard>

          {
            // Don't show organization select if user that is being edited or created is reside admin
            values.roleId !== userTypes.RESIDE_ADMIN && (
              <OrganizationSelect organizations={organizations} />
            )
          }

          {
            // Don't show facilities select if user that is being edited or created is reside admin or organization admin
            values.roleId !== userTypes.ORGANIZATION_ADMIN &&
              values.roleId !== userTypes.RESIDE_ADMIN && <FacilitiesTags />
          }
          {deleteUserButton}
        </FormSlide>
      </FormGrid>
    </StyledScrollableErrorArea>
  );
};

const mapState = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const UserFormBody = connect(mapState)(UserFormBodyRenderer);
