import {UserDto, CreateStaffUserDto} from "@reside/reside-api-app";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {History} from "history";

import {UserService} from "../../../services/UserService";
import {buildResponseErrorMessage} from "../../../services/api/errorHelpers";

export const useEditUser = (user: UserDto, history: History) =>
  useMutation((data: CreateStaffUserDto) => UserService.update(user.id, data), {
    onSuccess: () => {
      Alert.success("User was successfully updated.");
      history.push("/admin/users");
    },
    onError: async (resp: any) => {
      Alert.error(
        await buildResponseErrorMessage(
          resp,
          "An error occurs when updating user.",
        ),
      );
    },
  });
