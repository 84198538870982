import React from "react";
import {useQuery} from "react-query";
import {Button} from "@reside/ui";

import {DocumentTitle} from "../../atoms/document-title";
import {AdminLayout} from "../../atoms/admin-layout";
import {BackdropSpinner} from "../../atoms/spinner";
import {http} from "../../services/api/restApiClient";

/**
 * Component for the admin content page.
 *
 * This component displays the content status for the admin panel and provides a button to refresh the status data.
 *
 * Data rendered is plain JSON or nothing.
 *
 * @returns {JSX.Element} The rendered admin content page component.
 */
export const PageAdminContent = (): JSX.Element => {
  const {data, isLoading, refetch} = useQuery(
    ["get_content_status"],
    async () => {
      const {data} = await http.get<{data: any}>("/template-status");

      return data;
    },
    {
      refetchOnMount: true,
    },
  );

  return (
    <BackdropSpinner active={isLoading}>
      <DocumentTitle title="Reside - Admin - Content" />
      <AdminLayout>
        <Button
          onClick={() => refetch()}
          style={{width: "fit-content", marginBottom: "32px"}}
        >
          Refresh status
        </Button>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </AdminLayout>
    </BackdropSpinner>
  );
};
