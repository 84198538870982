import {useEffect, useState} from "react";
import {usePdfTemplatesQuery} from "../../../services/hooks/usePdfTemplatesQuery";
import {useSftpConfigsQuery} from "../../../services/hooks/useSftpConfigsQuery";
import {useTemplateFieldsQuery} from "../../../services/hooks/useTemplateFieldsQuery";
import {useTemplateNamesQuery} from "../../../services/hooks/useTemplateNamesQuery";
import {useParams} from "react-router";
import {useEventTriggersQuery} from "../../../services/hooks/useEventTriggersQuery";
import {isArray} from "lodash";

// Custom hook for managing event trigger form fields and related data
export const useEventTriggerFormFields = () => {
  const [templateName, setTemplateName] = useState<string>();
  const [facilities, setFacilities] = useState<Array<string>>();

  // Fetch template names, fields, PDF templates, and SFTP configs
  const {isLoading: isLoadingTemplateNames, data: templateNames = []} =
    useTemplateNamesQuery();
  const {data: templateFields, isLoading: isLoadingTemplateFields} =
    useTemplateFieldsQuery(templateName, [{enabled: !!templateName}]);
  const {data: pdfTemplates, isLoading: isLoadingPdfTemplates} =
    usePdfTemplatesQuery({templateName, options: [{enabled: !!templateName}]});
  const {data: sftpConfigs, isLoading: isLoadingSftpConfigs} =
    useSftpConfigsQuery();

  // Get event trigger ID from URL params for edit mode
  const params = useParams<{eventTriggerId: string}>();
  const {
    data: eventTrigger,
    isLoading: isLoadingEventTrigger,
    isSuccess,
  } = useEventTriggersQuery({
    eventTriggerId: params.eventTriggerId,
    options: {enabled: !!params.eventTriggerId},
  });

  // Set template name and facilities when editing an existing event trigger
  useEffect(() => {
    if (eventTrigger && !isArray(eventTrigger)) {
      setTemplateName(eventTrigger.template_field.template_name);
      // extract only ids of facilites because edit request needs array of ids
      setFacilities(eventTrigger.facilities.map(facilitiy => facilitiy.id));
    }
  }, [isSuccess, eventTrigger]);

  // Prepare initial values for the form when editing an existing event trigger
  const initialValues = eventTrigger &&
    !isArray(eventTrigger) && {
      templateName: eventTrigger.template_field.template_name,
      document: eventTrigger.document,
      template_field_id: eventTrigger.template_field.id,
      facilities,
      sftp_config_id: eventTrigger.sftp_config.id,
      document_path: eventTrigger.document_path,
      upload_on: eventTrigger.upload_on || ["admission_approval"],
    };

  // Determine if loading indicator should be shown
  const activateBackdrop =
    isLoadingTemplateNames ||
    isLoadingTemplateFields ||
    isLoadingPdfTemplates ||
    isLoadingSftpConfigs;

  // Return all necessary data and functions for the form
  return {
    templateName,
    setTemplateName,
    facilities,
    setFacilities,
    templateFields,
    templateNames,
    pdfTemplates,
    sftpConfigs,
    activateBackdrop,
    isLoadingEventTrigger,
    initialValues,
    eventTriggerId: params?.eventTriggerId,
  };
};
