import {ChangeEvent} from "react";
import {SelectField, Button, Multiselect, FloatingFieldLabel} from "@reside/ui";
import {Form, useFormikContext} from "formik";
import {connect} from "react-redux";
import {BackdropSpinner} from "../../atoms/spinner";
import {AppState, select} from "../../store";
import {DropdownTreeSelect} from "../../atoms/dropdown-tree-select";
import {DataNode} from "rc-tree/lib/interface";
import {useEventTriggerFormFields} from "./hooks/useEventTriggerFormFields";
import {EventTriggerForm} from "../../services/HubAndSpokeService";
import {UploadOnStates} from "../../utils/constants";
import {truncateAnswer} from "./helpers";
import {isEmpty} from "lodash";
import {DocumentPaths} from "./DocumentPaths";

// Main component for rendering event trigger form fields
export const EventTriggersFormFieldsRenderer = ({
  facilitiesTree,
  onSubmit,
  isLoading = false,
}: {
  facilitiesTree: ReadonlyArray<DataNode>;
  onSubmit: (values: EventTriggerForm) => void;
  isLoading: boolean;
}): JSX.Element => {
  // Custom hook for managing form fields and data
  const {
    activateBackdrop,
    templateNames,
    setTemplateName,
    templateFields,
    pdfTemplates,
    facilities,
    setFacilities,
    sftpConfigs,
  } = useEventTriggerFormFields();

  const {values, errors, setFieldValue} = useFormikContext<EventTriggerForm>();

  // Create options for Upload On Multiselect with user-friendly labels
  const uploadOnOptions = [
    { label: "Admission Approval", value: UploadOnStates.admissionApproval },
    { label: "Admission Signed", value: UploadOnStates.admissionSigned },
  ];

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmit({...values, facilities});
      }}
    >
      <BackdropSpinner active={activateBackdrop}>
        {/* SelectField component for choosing a template */}
        <SelectField
          options={templateNames.map(template => ({
            label: template,
            value: template,
          }))}
          label="Template"
          name="templateName"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            const newTemplateName = event.target.value;
            setTemplateName(newTemplateName);
            setFieldValue("template_field_id", "");
          }}
        />
        {/* Conditional rendering of template fields */}
        {templateFields && (
          <SelectField
            name="template_field_id"
            label="Template Field"
            options={templateFields.reduce((accumulator, templateField) => {
              if (templateField.is_available_in_rule) {
                return [
                  ...accumulator,
                  {
                    label: templateField.friendly_name
                      ? templateField.friendly_name
                      : truncateAnswer(templateField.answer_translation_text),
                    value: templateField.id,
                  },
                ];
              }
              return accumulator;
            }, [])}
          />
        )}
        {/* Conditional rendering of PDF templates */}
        {pdfTemplates && (
          <SelectField
            name="document"
            label="Document"
            options={pdfTemplates.map(pdfTemplate => ({
              label: pdfTemplate,
              value: pdfTemplate,
            }))}
          />
        )}
        <br />
        {/* DropdownTreeSelect component for selecting facilities */}
        <DropdownTreeSelect
          checkedKeys={facilities || []}
          onCheck={(_checkedKeys, checkedLeafKeys) => {
            setFacilities(checkedLeafKeys);
          }}
          treeData={facilitiesTree}
          triggerLabel="Facilities"
        />
        {/* Conditional rendering of SFTP configs */}
        {sftpConfigs && (
          <SelectField
            name="sftp_config_id"
            label="SFTP Config"
            options={sftpConfigs.map(sftpConfig => ({
              label: sftpConfig.name
                ? `${sftpConfig.name} - ${sftpConfig.hostname}`
                : sftpConfig.hostname,
              value: sftpConfig.id,
            }))}
          />
        )}
        {/* Upload On Multiselect */}
        <div style={{ marginBottom: '20px' }}>
          <FloatingFieldLabel visible>
            Upload On
          </FloatingFieldLabel>
          <Multiselect
            name="upload_on"
            placeholder="select options"
            testId="upload-on-multiselect"
            options={uploadOnOptions}
            selected={values.upload_on || [UploadOnStates.admissionApproval]}
            onChange={(selectedValues: ReadonlyArray<string>) => {
              setFieldValue("upload_on", selectedValues);
            }}
          />
        </div>

        <DocumentPaths />

        {/* Submit button with conditional rendering and disabled state */}
        {
          <Button
            style={{marginTop: "30px"}}
            type="submit"
            size="medium"
            disabled={
              isLoading ||
              !(
                facilities &&
                facilities.length > 0 &&
                values.document &&
                values.sftp_config_id &&
                values.template_field_id &&
                isEmpty(errors)
              )
            }
          >
            {isLoading ? "Saving..." : "Save"}
          </Button>
        }
      </BackdropSpinner>
    </Form>
  );
};

// Redux connection for accessing facilitiesTree from the state
const mapStateToProps = (state: AppState) => ({
  facilitiesTree: select.adminSession.facilitiesTree(state),
});

export const EventTriggersFormFields = connect(mapStateToProps)(
  EventTriggersFormFieldsRenderer,
);
