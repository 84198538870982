import {RoleDto} from "@reside/reside-api-app";
import {ReactAppEnvironment} from "../config";
import {AdmissionFromSearch} from "../services/AdmissionsService";

export const userTypes: Record<RoleDto.NameEnum, string> = {
  [RoleDto.NameEnum.RESIDENT]: "unknown", // TODO: remove
  [RoleDto.NameEnum.ADMISSIONS_DIRECTOR]:
    "92945475-f6a3-4626-bf28-f193e39e77e0",
  [RoleDto.NameEnum.CLERK]: "2476f5c2-17c1-4b6c-9968-026ef13f28d7",
  [RoleDto.NameEnum.FACILITY_ADMIN]: "978ce9a5-4a7d-4c70-89b1-2e678602a80e",
  [RoleDto.NameEnum.ORGANIZATION_ADMIN]: "90675b1c-8b5f-4684-91df-f39210ff58c4",
  [RoleDto.NameEnum.REGIONAL_ADMIN]: "61df4ecc-d7b9-47a6-9537-5d0266d25de2",
  [RoleDto.NameEnum.RESIDE_ADMIN]: "a512e3bc-b145-4e7b-afec-f5e5300730e2",
};

export const visibleRolesFor = (role: RoleDto.NameEnum): RoleDto.NameEnum[] => {
  switch (role) {
    case RoleDto.NameEnum.RESIDE_ADMIN:
      return [
        RoleDto.NameEnum.CLERK,
        RoleDto.NameEnum.FACILITY_ADMIN,
        RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
        RoleDto.NameEnum.REGIONAL_ADMIN,
        RoleDto.NameEnum.ORGANIZATION_ADMIN,
        RoleDto.NameEnum.RESIDE_ADMIN,
      ];
    case RoleDto.NameEnum.ORGANIZATION_ADMIN:
      return [
        RoleDto.NameEnum.CLERK,
        RoleDto.NameEnum.FACILITY_ADMIN,
        RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
        RoleDto.NameEnum.REGIONAL_ADMIN,
        RoleDto.NameEnum.ORGANIZATION_ADMIN,
      ];
    case RoleDto.NameEnum.REGIONAL_ADMIN:
      return [
        RoleDto.NameEnum.CLERK,
        RoleDto.NameEnum.FACILITY_ADMIN,
        RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
        RoleDto.NameEnum.REGIONAL_ADMIN,
      ];
    case RoleDto.NameEnum.ADMISSIONS_DIRECTOR:
      return [
        RoleDto.NameEnum.CLERK,
        RoleDto.NameEnum.FACILITY_ADMIN,
        RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
      ];
    case RoleDto.NameEnum.FACILITY_ADMIN:
      return [RoleDto.NameEnum.CLERK, RoleDto.NameEnum.FACILITY_ADMIN];
    case RoleDto.NameEnum.CLERK:
      return [RoleDto.NameEnum.CLERK];
    default:
      return [];
  }
};

export type UsStateCode =
  | "AL"
  | "AK"
  | "AS"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "DC"
  | "FM"
  | "FL"
  | "GA"
  | "GU"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MH"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "MP"
  | "OH"
  | "OK"
  | "OR"
  | "PW"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VI"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY";

export enum UsStates {
  AL = "Alabama",
  AK = "Alaska",
  AS = "American Samoa",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District Of Columbia",
  FM = "Federated States Of Micronesia",
  FL = "Florida",
  GA = "Georgia",
  GU = "Guam",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MH = "Marshall Islands",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  MP = "Northern Mariana Islands",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PW = "Palau",
  PA = "Pennsylvania",
  PR = "Puerto Rico",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VI = "Virgin Islands",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export const STATES = [
  {label: "Alabama", id: "84efe283-45a0-4e82-9af7-4899bce7c1b6"},
  {label: "Alaska", id: "db6a2ff7-f5c3-4f53-9dce-b36144de8d5c"},
  {label: "American Samoa", id: "10f62aff-77fc-408a-adeb-df4445c4e71f"},
  {label: "Arizona", id: "635922ef-692c-4656-9058-f6047319af3f"},
  {label: "Arkansas", id: "8b5cf4c2-692f-4135-a797-0674452d348b"},
  {label: "California", id: "77d483ab-91a3-4d72-a235-b8a61dd9ad5b"},
  {label: "Colorado", id: "157ad14e-3372-47c6-ad2d-95fba8bfbe0f"},
  {label: "Connecticut", id: "f9d2700b-8b15-4261-bfa1-7402419f8162"},
  {label: "Delaware", id: "1c855233-6f96-456b-80d4-f2c781b7028d"},
  {label: "District of Columbia", id: "d3206c50-cc45-458b-b6fc-bacee64495d8"},
  {label: "Florida", id: "224735d0-021c-4dd5-bcab-044369abc891"},
  {label: "Georgia", id: "829b05aa-d067-427d-8182-e72eda7b0835"},
  {label: "Guam", id: "135d1f22-29cf-4d12-91ae-cee9fd1f4202"},
  {label: "Hawaii", id: "9544908e-c460-4fec-8c18-eb67a10c2845"},
  {label: "Idaho", id: "04abbb26-595b-4111-8096-b2ad3c535145"},
  {label: "Illinois", id: "dc8cae26-3cae-4304-b013-7132e574ac17"},
  {label: "Indiana", id: "9dce70f0-f161-4e79-a619-425aed4c0c29"},
  {label: "Iowa", id: "51324d49-e0ab-418c-9bfd-e05f751f19a5"},
  {label: "Kansas", id: "4f0d71fb-08d1-48ec-8e98-3cfd2b8eb13b"},
  {label: "Kentucky", id: "0ca0e135-b3d9-496b-abb7-3bf59a0e0af3"},
  {label: "Louisiana", id: "5c75b759-6baa-4859-a00b-a862e41cd4fc"},
  {label: "Maine", id: "19e1899f-966c-49a4-b288-c34b72da4c85"},
  {label: "Maryland", id: "545816db-4b0e-4eaa-ba4b-b682b61b9769"},
  {label: "Massachusetts", id: "1ea0e8ad-3f51-4cdf-b8e0-e31bca400298"},
  {label: "Michigan", id: "f511706f-579b-4fed-a0fb-d2308520ad14"},
  {label: "Minnesota", id: "68b0a88d-d5ec-4626-a897-39aff6917b2d"},
  {label: "Mississippi", id: "825f6b6a-05f2-4c6c-af79-ae4948634905"},
  {label: "Missouri", id: "9b304245-cb30-42a1-9f21-71eaebdbe518"},
  {label: "Montana", id: "3dfec54f-e120-486f-9cd0-2ab46c788218"},
  {label: "Nebraska", id: "b6d5b41e-61bd-4e94-a1e7-f0f0f3f2a220"},
  {label: "Nevada", id: "cfa34198-2dee-4df1-8a1c-4b5558264eaf"},
  {label: "New Hampshire", id: "4b024c12-e043-4acf-9b2e-10b94687ca2f"},
  {label: "New Jersey", id: "b1d383d5-f489-478b-a62c-1bac3e60c3e2"},
  {label: "New Mexico", id: "7efafca8-6d2c-4a27-aea7-9c2fbb019b45"},
  {label: "New York", id: "2bfdf2d9-0ffa-4f01-9bb8-6a3e8e8e4707"},
  {label: "North Carolina", id: "b1c540cb-6845-41d5-a247-a86af2c89ca9"},
  {label: "North Dakota", id: "1920342d-a386-4e4b-b07c-d98612c0ea8b"},
  {
    label: "Northern Mariana Islands",
    id: "b5e6acaa-67b1-4d6c-9dc8-1d374fcfbf6c",
  },
  {label: "Ohio", id: "a282356e-342b-40ca-96f7-a438136a5b5d"},
  {label: "Oklahoma", id: "7d767936-dcc0-480b-a4c9-fee6b519871e"},
  {label: "Oregon", id: "9f5b4a1f-1cf3-4310-a398-e8b6f517eb1c"},
  {label: "Palau", id: "441dbb1d-3499-4a6f-8c43-5da9408d1a80"},
  {label: "Pennsylvania", id: "1b1af448-55fe-42e4-9971-9806c4bd49ec"},
  {label: "Puerto Rico", id: "1e6c203f-bef2-4d69-be76-147084851ee7"},
  {label: "Rhode Island", id: "78c56b52-cbe3-4084-9fe6-7e156d899787"},
  {label: "South Carolina", id: "cc87dc5c-0b4b-48e8-b97c-ce2ab405403f"},
  {label: "South Dakota", id: "2cbc8d80-5c76-4c0c-882a-76863c80a1a4"},
  {label: "Tennessee", id: "9360e964-4665-477a-bbe9-2b72e0a9f5cd"},
  {label: "Texas", id: "989a07a1-a923-41bd-97e5-b7453a26b992"},
  {label: "Utah", id: "8ceb7ce0-66ee-47c7-89dd-4c9c171a22ab"},
  {label: "Vermont", id: "4ecc2933-c546-45ac-a51e-65cbe1f8aa17"},
  {label: "Virgin Islands", id: "251bc600-7bc3-4453-b355-b8e140751a99"},
  {label: "Virginia", id: "8ccfe66f-e87d-4e50-aa02-ab20982b4d65"},
  {label: "Washington", id: "35f19401-0c58-4649-a8a1-1740e5ef6478"},
  {label: "West Virginia", id: "b1f0912c-75ed-4fee-8f2f-240a1ed092e7"},
  {label: "Wisconsin", id: "28c1ea33-ed6a-4e30-b96c-a3a242f11432"},
  {label: "Wyoming", id: "5079d64f-60ba-46cc-ba7d-e0ace4dcc1c7"},
];

export enum GENDERS {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export interface AdmissionsForStatus {
  number: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
  content: ReadonlyArray<AdmissionFromSearch>;
}

export const admissionsForStatusInitialState: AdmissionsForStatus = {
  number: 0,
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  content: [],
};

export const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
};

export type Relationship =
  | "GUARDIAN_OF_THE_PERSON"
  | "GUARDIAN_OF_THE_ESTATE"
  | "POWER_OF_ATTORNEY"
  | "IMMEDIATE_FAMILY";

export const ASSETS_BUCKET_URL: Record<ReactAppEnvironment, string> = {
  DEVELOP: "com.resideadmissions.develop.assets",
  TEST: "com.resideadmissions.test.assets",
  "STAGE-NEW": "com.resideadmissions.test.assets",
  STAGING: "com.resideadmissions.staging.assets",
  PRODUCTION: "com.resideadmissions.assets",
};

export enum PdfUploadStatus {
  PENDING = "PENDING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum AdvancedDirectiveStatus {
  YES = "9ebcd7aa-79aa-4b4b-9a61-a9e7a8d7003b",
  NO = "093832bf-9b64-4af6-bfa5-fe784b7171ec",
}

export enum PrimaryPayerTypeId {
  medicaid = "63175f6f-a3f9-475c-9260-f80e723aba1a",
  medicareA = "f5f29796-a9fa-4a0a-9726-ad6340b642eb",
  other = "4108ba55-85df-487d-ae9e-f97594b27071",
  private = "3abb74f8-9792-4867-a24d-bbd785d3e444",
  managedCare = "4108ba55-85df-487d-ae9e-f97594b27071",
  medicareB = "4108ba55-85df-487d-ae9e-f97594b27071",
  medicareD = "4108ba55-85df-487d-ae9e-f97594b27071",
  outpatient = "4108ba55-85df-487d-ae9e-f97594b27071",
}

export enum OtherPayerTypeId {
  medicaid = "69a82606-359a-4610-b303-327c1b51e926",
  medicareA = "3085541c-594e-481a-803c-c7481264d88f",
  other = "be20961f-6c4c-47ac-9946-48c342b19d51",
  private = "0e61c105-f9ac-4ce7-b516-d2178d41e3d4",
  managedCare = "be20961f-6c4c-47ac-9946-48c342b19d51",
  medicareB = "be20961f-6c4c-47ac-9946-48c342b19d51",
  medicareD = "be20961f-6c4c-47ac-9946-48c342b19d51",
  outpatient = "be20961f-6c4c-47ac-9946-48c342b19d51",
}

export enum PrefligtAnswerId {
  fistName = "5baafde7-823b-40f3-aaa6-05d58a72ff53",
  lastName = "9d8436a0-e39e-42f6-8481-82669c79a69b",
  gender = "ae9ec5e0-f168-4721-9e6d-f2e017e3f25c",
  birthDate = "df765cef-560b-4bea-bd1c-78d6c1df5bec",
  ssn = "13ad8244-2be0-47ab-8510-3555dcff3e59",
  phone = "d93a557e-1709-45c2-a04c-0b229ebe7790",
  email = "116bee5b-dab5-49ae-8445-44c1ecd58702",
  addressStreet = "cca145ca-9526-4cf2-930b-d457abff8d2d",
  addressNumber = "c808888d-636a-46c6-8207-13ac67fd212f",
  addressCity = "9da20692-e29e-48d4-8c01-b0cb2f3a209c",
  addressState = "bf0c6d53-ab41-4db5-a667-a3451b04770a",
  addressZipCode = "bd4a6c81-a147-484c-b167-e00fde0d4a41",
  dateOfAdmission = "df765cef-560b-4bea-bd1c-78d6c1df5bec",
  roomNumber = "13ad8244-2be0-47ab-8510-3555dcff3e59",
  AdvancedDirective = "d48e634a-8789-45d5-b841-455ec0065dbc",
  primaryPayerSource = "0be035a7-af0e-484d-ab43-4f3ffdf543e5",
  daysRemaining = "e6b376dd-c270-479b-9fd7-5769109f12d8",
  medicareAHicNumber = "902abc4c-ecb3-4019-8964-6521f7c7f871",
  secondaryPayerSource = "9a5b3be6-9894-4892-b7f4-e8e499848ad6",
  secondaryDaysRemaining = "c753855d-4d66-4861-9fff-ae3a2eaf64e7",
  secondaryMedicareAHicNumber = "7067163f-7912-4572-bcab-6a6b179c7658",
  medicaidNumber = "3a438e29-30a7-4cf9-96f5-ff439beaec11",
  secondaryMedicaidNumber = "1e309455-d96a-4ae3-9995-2159a3f7d8b0",
  dateOfPccDataCapture = "b46d35f2-f1ef-4c6c-b310-02e95b0a510a",
}

export enum SftpAuthMethods {
  password = "password",
  privateKey = "private_key",
}

export enum UploadOnStates {
  admissionApproval = "admission_approval",
  admissionSigned = "admission_signed",
}