import React, {FunctionComponent, ReactNode} from "react";
import {CreateStaffUserDto} from "@reside/reside-api-app";
import {FormHeader, Button} from "@reside/ui";
import {useFormikContext} from "formik";

import {Space} from "../../../atoms/space";
import {ButtonLink} from "../../../atoms/button-link";

export type Props = {
  title: ReactNode;
  isSubmitting: boolean;
};

export const UserFormHeader: FunctionComponent<Props> = ({
  title,
  children,
  isSubmitting,
}) => {
  const {submitForm} = useFormikContext<CreateStaffUserDto>();

  return (
    <FormHeader>
      {({Wrapper, Left, Right}) => (
        <Wrapper>
          {title}
          <Left>
            <Space>{children}</Space>
          </Left>
          <Right>
            <Space>
              <ButtonLink to="/admin/users">Cancel</ButtonLink>
              <Button
                type="submit"
                disabled={isSubmitting}
                onClick={submitForm}
                testId="saveButton"
                color="success"
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Space>
          </Right>
        </Wrapper>
      )}
    </FormHeader>
  );
};
