import {hubAndSpokeHttp} from "./api/hubAndSpokeApiClient";
import {getAuthorizationHeader} from "../models/admin-session/selectors";

export type SftpConfig = {
  id?: string;
  name?: string;
  notes?: string;
  hostname: string;
  port: number;
  username: string;
  auth_type: string;
  auth_value: string;
};

export type TemplateField = {
  id: string;
  template_name: string;
  question_id: string;
  question_type: string;
  question_data_type: string;
  question_translation_key: string;
  question_translation_text: string;
  answer_id: string;
  answer_translation_key: string;
  answer_translation_text: string;
  friendly_name: string;
  is_available_in_rule: boolean;
  is_deleted: boolean;
};

export type EventTrigger = {
  id: string;
  template_field: TemplateField;
  facilities: Array<{
    id: string;
    name: string;
    code: string;
    organization_id: string;
    address_id: string;
    phone: string;
    email: string;
    status: string;
    version: number;
    primary_contact_id: string;
    time_zone_id: string;
    template_name: string;
  }>;
  sftp_config: SftpConfig;
  document: string;
  document_path: string;
  upload_on: string[];
};

export type EventTriggerForm = {
  templateName: string;
  document: string;
  facilities: string[];
  template_field_id: string;
  sftp_config_id: string;
  document_path: string;
  upload_on: string[];
};

export type PDFTemplate = {
  type: string;
  name: string;
  key: string;
  contentType: string;
};

export const HubAndSpokeService = {
  getSftpConfigs: (query?: any) =>
    hubAndSpokeHttp.get<Array<SftpConfig>>("/sftp-configurations/", query, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  findSftpConfig: (id: string) =>
    hubAndSpokeHttp.get(`/sftp-configurations/${id}/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  createSftpConfig: (values: SftpConfig) =>
    hubAndSpokeHttp.post("/sftp-configurations/", values, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  updateSftpConfig: (id: string, values: SftpConfig) =>
    hubAndSpokeHttp.put(`/sftp-configurations/${id}/`, values, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  deleteSftpConfig: (id: string) =>
    hubAndSpokeHttp.delete(`/sftp-configurations/${id}/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  testConnectivity: (values: SftpConfig) =>
    hubAndSpokeHttp.post("/sftp-configurations/connect/", values, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  getTemplateFields: (query?: any) =>
    hubAndSpokeHttp.get<Array<TemplateField>>("/template-fields/", query, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  testUpload: (body: {sftp_config_id: string; target_path: string}) =>
    hubAndSpokeHttp.post("/sftp-configurations/test-upload/", body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  patchTemplateFields: ({
    templateFieldId,
    friendlyName,
    isAvailableInRule,
  }: {
    templateFieldId: string;
    friendlyName: string;
    isAvailableInRule?: boolean;
  }) =>
    hubAndSpokeHttp.patch(
      `/template-fields/${templateFieldId}/`,
      {
        friendly_name: friendlyName,
        is_available_in_rule: isAvailableInRule,
      },
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),

  getEventTriggers: ({
    eventTriggerId,
    templateName,
  }: {
    eventTriggerId?: string;
    templateName?: string;
  }) =>
    hubAndSpokeHttp.get<Array<EventTrigger> | EventTrigger>(
      eventTriggerId
        ? `/event-triggers/${eventTriggerId}/`
        : "/event-triggers/",
      {
        template_field__template_name: templateName,
      },
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),

  createEventTrigger: (body: {
    document: string;
    facilities: Array<string>;
    template_field_id: string;
    sftp_config_id: string;
    document_path: string;
  }) =>
    hubAndSpokeHttp.post<EventTrigger>("/event-triggers/", body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  deleteEventTrigger: (eventTriggerId: string) =>
    hubAndSpokeHttp.delete<string>(
      `/event-triggers/${eventTriggerId}/`,
      {},
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),

  editEventTrigger: (
    eventTriggerId: string,
    body: {
      document: string;
      facilities: Array<string>;
      template_field_id: string;
      sftp_config_id: string;
      document_path: string;
    },
  ) =>
    hubAndSpokeHttp.put<EventTrigger>(
      `/event-triggers/${eventTriggerId}/`,
      body,
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),

  getPdfTemplates: (templateName: string) =>
    hubAndSpokeHttp.get<Array<PDFTemplate>>(
      `/pdf-templates/${templateName}/`,
      {},
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),
};
